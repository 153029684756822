@import "../../app.scss";
.rightbar{
    @include themify($themes){
        flex: 2;
        height: 100vh;
        top: 0px;
        position: sticky;
        background-color: themed("bgSoft");
        overflow: scroll;
        &::-webkit-scrollbar{
            display: none;
        }

        @include mobile{
            display: none;
        }

        @include tablet{
            display: none;
        }
        form{
            position: sticky;
            top: 0;
            height: 12vh;
            .search{
                color: themed("textColor");
                display: flex;
                justify-content: center;
                align-items: center;
                position: sticky;
                top: 0;
                padding: 20px;
                height: 12vh;
                background-color: themed("bgSoft");
    
                .textInput{
                    width: 100%;
                    padding: 12px;
                    padding-right: 35px;
                    border:none;
                    background-color: themed("bg");
                    border-radius: 14px;
                    font-size: 14px;
                    color: themed("textColor");
                }
    
                .SearchIcon{
                    position: absolute;
                    right: 8%;
                    cursor: pointer;
                }
            
           }
    
        }
       
       .popular{
            display: flex;
            flex-direction: column;

            .popularTitle{
                display: flex;
                gap: 8px;
                align-items: center;
                color: themed("accentColor");
                font-size: 14px;
                font-weight: 600;
                position: sticky;
                top: 12vh;
                background-color: themed("bgSoft");
                height: 30px;
                padding-bottom: 10px;
            }

        .animeContainer{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 20px 0;
        }
       }
    }
}