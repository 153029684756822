@import "../../app.scss";

.DetailCard{
    @include themify($themes){
        background-color: themed("bgSoft");
        color: themed("textColor");
        display: flex;
        justify-content: center;
        gap: 15px;
        cursor: pointer;

        .imgContainer{
            flex: 1;
            width: 80px;
            overflow: hidden;
            border-radius: 20px;
            height: 80px;
            img{
                width: 100%;
            }
        }
        .textContent{
            flex: 3;

            .title{
                font-size: 14px;
                font-weight: bold;
            }
            .desc{
                font-size: 11px;
            }
        }
    }
}