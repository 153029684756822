@import "../../app.scss";
.home{
        @include themify($themes){
        
        background-color: themed("bgSoft");
        height: 100vh;
        overflow: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
        .mobileIcon{
            width: 50px;
            height: 50px;
            border-radius: .5rem;
            display: none;
            overflow: hidden;
            img{
                width: 100%;
            }

            @include mobile{
                display: block;
            }
        }

        .cardContainer{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 20px;
            padding: 1%;
            
            @include mobile{
                padding:  0.8rem 0;
                padding-right: .6rem;
                justify-content: space-around;
                gap: 5px;
            }
        }

    }
}
