@import '../../app.scss';

.episodeCard{
    @include themify($themes){
        width: 23.33%;
        cursor: pointer;
        padding: 10px;

        @include mobile{
            width: 32%;
            padding:10px;
        }

        .episodeImg{
            max-height: 110px;
            overflow: hidden;
            border-radius: 8px;
            
            img{
                width: 100%;
                min-height: 110px;
                @include mobile{
                    min-height: 80px;
                }
            }
        }

        .contentCont{
            
            h6{
                color: themed("textColor");
                font-size: 14px;
                margin: 5px 0;
                
                @include mobile{
                    font-size: 12px;
                }
            }

            p{
                color: themed("textColor");
                font-size: 12px;

                @include mobile{
                    font-size: 10px;
                }
                
            }
        }
    }
}

.episodeCard:hover,
.episodeCard:active{
    @include themify($themes){
        background-color: themed("accentColor");
        border-radius: 8px;
        
        .contentCont{
            h6{
                color: #000;
            }

            p{
                color: #000;
                font-weight: 500;
            }
        }
    }
}