@import "../../app.scss";
.trending{
        @include themify($themes){
        
        background-color: themed("bgSoft");
        height: 100vh;
        overflow: scroll;
        &::-webkit-scrollbar{
            display: none;
        }
        .mobileIcon{
            width: 50px;
            height: 50px;
            border-radius: .5rem;
            display: none;
            overflow: hidden;
            img{
                width: 100%;
            }

            @include mobile{
                display: block;
            }
        }

        .title{
            z-index: 99;
            display: flex;
            justify-content: space-between;
            position: sticky;
            height: 12vh;
            background-color: themed("bgSoft");
            flex: 1;
            top: 0px;
            padding: 0 20px;
            align-items: center;
            color: themed("textColor");

            h1{
                display: flex;
                align-items: center;
                gap: 12px;

                .iconName{
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    background-color: themed("accentColor");
                    border-radius: 4px;
                }
            }

            @include mobile{
                height: 9vh;
                padding: 0 10px;
                h1{
                    display: none;
                }
            }

           
        }

        .cardContainer{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 20px;
            padding: 1%;
            
            @include mobile{
                padding:  0.8rem 0;
                padding-right: .6rem;
                justify-content: space-around;
                gap: 5px;
            }
        }

    }
}
