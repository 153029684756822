@import "../../app.scss";
.Anime{
        @include themify($themes){
        
        background-color: themed("bgSoft");
        height: 100vh;
        overflow: scroll;
        &::-webkit-scrollbar{
            display: none;
        }

        .mobileIcon{
            width: 50px;
            height: 50px;
            border-radius: .5rem;
            display: none;
            overflow: hidden;
            img{
                width: 100%;
            }

            @include mobile{
                display: block;
            }
        }

        .title{
            z-index: 99;
            display: flex;
            justify-content: space-between;
            position: sticky;
            height: 12vh;
            background-color: themed("bgSoft");
            flex: 1;
            top: 0px;
            padding: 0 20px;
            align-items: center;
            color: themed("accentColor");

            @include mobile{
                height: 9vh;
                padding: 0 10px;
                h1{
                    display: none;
                }
            }
        }

        .mainContent{
            max-width: 100%;
            padding: 20px;
            img{
                width: 100%;
            }

            .videoPlayer{
                width: 100%;
                height: 500px;
            }
            @include mobile{
                padding: 10px
            }
            .coverPic{
                width: 100%;
                height: 240px;
                border-radius: 1rem;
                background-size: contain;
                position: relative;
                overflow: hidden;
                .overlay{
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: rgb(0,0,0);
                    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0.017542051000087544) 100%);

                }
                .coverCard{
                    position: absolute;
                    bottom: 4%;
                    left: 1.5%;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    width: 100%;

                    .desc{
                        font-size: .8rem;
                        color: #fff;
                        max-width: 80ch;
                    }
                    
                    img{
                        width: 100px;
                        border-radius: 1rem;
                    }
                    p{
                        color: themed("accentColor");
                        font-weight: 500;
                        letter-spacing: .1rem;
                        font-size: 1.2rem;
                        margin-bottom: 10px;
                    }
                }
            }
            .sectitle_cont{
                display: flex;
                justify-content: space-between;
                align-items: center;

                .sectitle{
                    font-size: 18px;
                    color: themed("accentColor");
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin: 20px 0;
    
                    @include mobile{
                        font-size: 16px;
                    }
                }

                .nextEpTime{
                    color: themed("textColor");
                    font-size: .8rem;
                }

                .dubSub{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    text-transform: uppercase;
                    color: themed("textColor");
                    font-size: .7rem;
                }
            }
           

            .episodeCont{
                display: flex;
                flex-wrap: wrap;
                gap: 15px;

                @include mobile{
                    gap: 6px;
                }
            }
        }
    }
    .loader{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
}