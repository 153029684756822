@import "../../app.scss";
.cardContainerLink{
    width: 23.33%;
    max-width: 200px;

    
    @include mobile{
        min-width: 100px;
    }
   
    @include themify($themes){
    .Card{
            color: themed("textColor");
            transition: transform 352ms ease-in-out;
            cursor: pointer;

            @include mobile{
                min-width: 110px;
                margin-bottom: .5rem;
            }
    
            .imgContainer{
                border-radius: 10px;
                max-height: 280px;
                @include mobile{
                    min-width: 110px;
                    max-height: 145px;
                }
                overflow: hidden;
                object-fit: cover;
                img{
                    width: 100%;
                    min-height: 280px;
                    @include mobile{
                        min-height: 145px;
                    }
                }
            }
    
            .titleContainer{
                display: flex;
                flex-direction: column;
    
                .cardTitle{
                    font-size: .8rem;
                    margin-bottom: 5px;
                    font-weight: 600;
                    margin-top: 10px;

                    @include mobile{
                        font-size: .6rem;
                    }
                }
                .rating{
                    font-size: 12px;
                    @include mobile{
                        font-size: .5rem;
                    }
                }
            }
    
        }
        
        .Card:hover{
            transform: scale(1.05);
        }
    }
}
