@import "../../app.scss";
.headertitle{
    @include themify($themes){
        z-index: 99;
        display: flex;
        justify-content: space-between;
        position: sticky;
        height: 12vh;

        background-color: themed("bgSoft");
        flex: 1;
        top: 0px;
        padding: 0 20px;
        align-items: center;
        color: themed("textColor");

        .switch{
            display: flex;
            align-items: center;
            gap: 5px;
        }        
        h1{
            display: flex;
            align-items: center;
            gap: 12px;

            .iconName{
                width: 30px;
                height: 30px;
                padding: 2px;
                color: #fff;
                background-color: themed("accentColor");
                border-radius: 4px;
            }
        }


        @include mobile{
            height: 9vh;
            padding: 0 10px;
            h1{
                display: none;
            }
        }
    }
}