@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

*{
    font-family: "roboto";
    margin: 0px;
    padding: 0;
    box-sizing: border-box;
}

h1{
    font-size: 1.6rem;
}

$themes: (
    light:(
        textColor: #000,
        bg: white,
        bgSoft: #f6f3f3,
        textColorSoft: #555,
        border: lightgray,
        accentColor: #fbae1f,
        hoverColor: #000

    ),
    dark:(
        textColor: whitesmoke,
        bg: #222,
        hoverColor: #fff,
        bgSoft: #333,
        textColorSoft: lightgray,
        border: #444,
        accentColor: #fbae1f
    )
);

@mixin themify($themes){
    @each $theme, $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;
            @each $key, $submap in $map{
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

@mixin mobile{
    @media (max-width: 600px){
        @content;
    }
}
@mixin tablet{
    @media (max-width: 960px){
        @content;
    }
}