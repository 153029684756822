@import "../../app.scss";

.leftbar{
    @include themify($themes){
        background-color: themed("bgSoft");
        flex: 1;
        padding: 0 20px;
        top: 0px;
        height: 100vh;
        position: sticky;
        z-index: 9;

        @include mobile{
            display: none;
        }
        
        .logo{
            width: 3.5rem;
            margin-top: 10px;
            border-radius: 10px;
        }
        
        nav{
            margin-top: 60px;
            ul{
                display: flex;
                flex-direction: column;
                gap: 15px;
                li{
                   
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 12px;
                    border-radius: 8px;
                    font-size: 14px;
                    list-style-type: none;
                    min-width: 150px;
                    cursor: pointer;
                    font-family: "roboto";
                    color: themed("textColor");
                }
    
                li:hover{
                    color: themed("accentColor");
                    border-right: 5px solid themed("accentColor");
                    background-color: themed("bg");
                }
    
                .active{
                    color: themed("accentColor");
                    border-right: 5px solid themed("accentColor");
                    background-color: themed("bg");
                }
            }
        }
    }
}